.listingPage-container {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
}

.listing-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}


.listing-container {
  position: relative;
  flex: 2;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.favorite-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-top: 5px;
  margin-left: 5px;
  background-color: #FF6F61;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
}

.favorite-icon:hover {
  background-color: #FF3B2E;
  box-shadow: 0 6px 10px rgba(255, 59, 46, 0.15);
  transform: translateY(-2px);
}

.favorite-icon:active {
  background-color: #CC3226;
  transform: translateY(0);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.favorite-icon.favorited {
  background-color: #FF0000;
  color: white;
  box-shadow: 0 4px 6px rgba(255, 0, 0, 0.4);
}

.favorite-icon.favorited:hover {
  background-color: #CC0000;
  box-shadow: 0 6px 10px rgba(204, 0, 0, 0.6);
}

.favorite-icon i {
  margin-left: 8px;
  transition: transform 0.3s ease-in-out;
}


.favorite-icon:hover i {
  transform: rotate(180deg);
}



.reserve-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-top: 5px;
  background-color: #5465FF;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
}

.reserve-button:hover {
  background-color: #4353CC;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.reserve-button:active {
  background-color: #2B3B99;
  transform: translateY(0);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.reserve-button.reserved {
  background-color: gold;
  color: black;
  box-shadow: 0 4px 6px rgba(255, 215, 0, 0.3);
}

.reserve-button.reserved:hover {
  background-color: #e5b800;
  box-shadow: 0 6px 10px rgba(255, 215, 0, 0.5);
}

.reserve-button i {
  margin-left: 8px;
  transition: transform 0.3s ease-in-out;
}

.reserve-button:hover i {
  transform: rotate(215deg);
}

.editor {
  margin-top: 10px;
  background-color: #415A77;
  color: white;
  border: none;
  font-size: 15px;
  height: 50px;
  width: 80px;
  letter-spacing: 2.5px;
  box-shadow: 0 2px 4px darkslategray;
  border-radius: 20%;
  align-self: flex-start;
}


.edit:active {
  box-shadow: 0 0 2px darkslategray;
}

.listing-title {
  font-size: 2.5rem;
  color: #2c3e50;
  font-weight: bold;
  margin-bottom: 5px;
}

.listing-details p {
  font-size: 1.5rem;
  margin: 8px 0;
  line-height: 1.6;
}

.listing-details strong {
  color: #1a202c;
  font-size: 1.5rem;
}

.listing-image-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.listing-page-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.listing-content {
  display: grid;
  gap: 12px;
}

.description {
  font-size: 1.3rem !important;
  margin-top: 10px;
  font-weight: 600;
  margin-bottom: 10px;
}

.detail-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.detail-row i {
  color: #5465FF;
}

.detail-row span {
  font-size: 1.15rem;
}

@media (max-width: 768px) {
  .listingPage-container {
    flex-direction: column;
    align-items: center;
  }

  .listing-container {
    padding: 30;
    max-width: 100%;
  }

  .listing-title {
    font-size: 1.5em;
  }

  .listing-details p {
    font-size: 0.9em;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px;
}

.modal-content {
  background-color: white;
  position: relative;
  padding: 20px;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: 90%;
}

.modal-content h3 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  text-align: center;
  color: #333;
}

.description-textarea {
  width: 100%;
  height: 80px;
  resize: none;
  padding: 5px 10px;
  margin-top: 3px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 0.9rem;
  color: #333;
  background-color: #f9f9f9;
}

.modal-content label {
  display: block;
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: #555;
}

.modal-content input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 0.9rem;
  color: #333;
  background-color: #f9f9f9;
}

.modal-content input:focus,
.modal-content textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(27, 38, 59, 0.4);
  outline: none;
}

.modal-content button {
  padding: 8px 16px;
  margin-right: 10px;
  margin-top: 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.save-edits-button {
  background-color: #1B263B;
  color: white;
}

.cancel-edits-button {
  background-color: #6c757d;
  color: white;
}

.delete-edits-button {
  background-color: red !important;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 15px;
}

.delete-edits-button:hover {
  background-color: darkred !important;
}

select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-size: 0.9rem;
  resize: none;
}

@media (max-width: 480px) {
  .modal-content {
    width: 95%;
    padding: 15px;
  }

  .modal-content button {
    font-size: 0.8rem;
    padding: 6px 12px;
  }
}

.no-access-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.no-access-message {
  max-width: 400px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.no-access-message h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.no-access-message p {
  font-size: 16px;
  color: #666;
}