@tailwind base;
@tailwind components;
@tailwind utilities;

.create-listing {
    max-width: 700px;
    margin: 0 auto;
    padding: 50px;

    border: 1px solid #ddd;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

#create-listing {
    font-size: 1.8em;
    margin-bottom: 10px;
    color: #1B263B;
    text-align: center;
    margin-bottom: 20px;
    letter-spacing: 5px;
}

.form-group {
    margin-bottom: 15px;
}

.error-text {
    color: red;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input[type='text'],
.form-group input[type='number'],
.form-group input[type='file'],
.form-group input[type="date"],
.form-group textarea,
.form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    resize: none;
}



.submit-button {
    width: 100%;
    padding: 10px;
    background-color: #415A77;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    margin-top: 20px;
}

.submit-button:hover {
    background-color: #778DA9;
}


.container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .login-message p {
    font-size: 1.2rem;
    color: #1b263b;
  }
  
  .content p {
    font-size: 1.2rem;
    color: #1b263b;
  }