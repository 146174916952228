@tailwind base;
@tailwind components;
@tailwind utilities;

.search-heading {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.875rem;
    letter-spacing: 5px;
    font-weight: 800;
    color: #1f2937;

    @media (min-width: 768px) {
        font-size: 3rem;
    }

    @media (min-width: 1024px) {
        font-size: 3rem;
    }
}



.search-bar-section {
    text-align: center;
    margin: 40px 0;
    justify-content: center;
}

.search-input {
    padding: 10px;
    width: 50%;
    font-size: 1em;
    outline: 3px ridge #2e3a46;
    border-radius: 1rem;
    margin-right: 10px;
    @media (max-width: 768px) {
       width: 70%;
    }
}

.search-button {
    padding: 10px 20px;
    font-size: 1em;
    background-color: #2e3a46;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 45px;
    padding: 20px;
}

.apartment-type {
    margin: 20px 0;
    font-size: 1.5rem;
    color: #264F7B;
}

.apartment-type {
    margin-left: 10px;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 800;
    letter-spacing: 2px;
    color: #1f2937;

    

    @media (min-width: 1024px) {
        font-size: 2.5rem;
    }

}

.carousel-item-padding {
    padding-left: 75px;
}

.carousel-container-padding {
    padding: 0 10px;
}
