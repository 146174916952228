@tailwind base;
@tailwind components;
@tailwind utilities;

.homepage {
    font-family: 'Roboto', 'sans-serif';
    min-height: 100vh;
}

.hero-image {
    position: relative;
    background-size: cover;
    background-position: center;
    padding: 50px 20px;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: left;
    z-index: 1; 
  }
  
  .hero-image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./../../images/hddcpic.jpg');
    background-size: cover;
    background-position: center;
    filter: brightness(0.4); 
    z-index: -1; 
  }

.hero-image h1 {
    font-size: 2.7em;
    color: #ffffff;
    text-shadow: 4px 4px 8px rgba(65, 90, 119, 1);
    margin-bottom: 10px;
}
.location {
    color: #f9f9f9;
}

.hero-image p {
    font-size: 1.4em;
    color: #ffffff;
    text-shadow: 4px 4px 8px rgba(65, 90, 119, 1);
    margin-bottom: 20px;
}

.cta-button {
    padding: 10px 20px;
    font-size: 1.2em;
    background-color: #415A77;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 20px;
    transition: transform 0.2s ease, background-color 0.2s ease;
}

.cta-button:active {
    transform: scale(0.95);
    background-color: #1d2a34;
}

.cta-button:hover {
    transform: scale(0.95);
}

.featured-listings {
    padding: 20px 20px;
    background-color: #f9f9f9;
    text-align: center;
}

.featured-listings-text {
    color: #264F7B;
    text-align: center;
    margin-bottom: 1rem;
    font-size: 2.5rem;
    letter-spacing: 2px;
    font-weight: 800;
}

.listings-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.view-details-button {
    padding: 10px 15px;
    margin: auto;
    background-color: #415A77;
    color: white;
    border: none;
    display: block;
    cursor: pointer;
    border-radius: 5px;
}
  .testimonials {
    padding: 2rem;
    background-color: #f9f9f9;
    text-align: center;
  }
  .testimonials-text {
    color: #264F7B;
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    letter-spacing: 2px;
    font-weight: 800;
  }
  .testimonials h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  
  .testimonials-carousel .testimonial {
    background-color: #ffffff;
    border-radius: 0.5rem;
    padding: 1.5rem;
    margin: 0 auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
  }
  
  .quote {
    font-size: 1.1rem;
    font-style: italic;
    color: #333;
  }
  
  .author {
    font-size: 0.9rem;
    font-weight: bold;
    color: #666;
    margin-top: 0.5rem;
  }
  



/* Media query for mobile scaling */
@media (max-width: 768px) {
    .hero-image {
        height: 80vh;
        padding: 30px 15px;
    }

    .hero-image h1 {
        font-size: 2em;
    }

    .hero-image p {
        font-size: 1.1em;
    }

    .cta-button {
        padding: 8px 16px;
        font-size: 1em;
    }

    .featured-listings-text {
        font-size: 2rem;
    }

<<<<<<< HEAD
=======
    .how-it-works h2 {
        font-size: 2rem;
    }

    .how-it-works p {
        font-size: 1em;
    }

    .step h3 {
        font-size: 1.5em;
    }

    .step p {
        font-size: 0.9em;
    }

    .register-button {
        padding: 8px 12px;
        font-size: 0.9em;
    }

    .featured-listings {
        display: none;
    }

    .how-it-works h2 {
        font-size: 1.5em;
        margin-bottom: 20px;
        color: #264F7B;
    }

    .step h3 {
        font-size: 1.2em;
    }

}
