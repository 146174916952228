@tailwind base;
@tailwind components;
@tailwind utilities;

.authentication-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}
.valid {
  color: green;
}

.invalid {
  color: red;
}

.password-requirements {
  display: block;
  justify-content: left;
}

.password-checklist {
  margin-top: 10px;
  font-size: 0.9em;
}
.authentication-container h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 40px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-align: center;
  }
.authentication-container h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #1B263B;
    margin-bottom: 20px;
    text-transform: capitalize;
    text-align: center;
    letter-spacing: 1.2px;
  }

.auth-sections {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.auth-section {
    width: 45%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.auth-section input {
    display: block;
    width: 90%;
    margin: 10px auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.auth-section button {
    margin: 10px 5px;
    padding: 10px 20px;
    border: none;
    background-color: #264f7b;
    color: white;
    cursor: pointer;
    border-radius: 4px;
}

.auth-section button:hover {
    background-color: #1D334C;
}

.spinner-container {
    display: flex; 
    align-items: center; 
    justify-content: center;
    /* position: fixed;  */
    /* top: 20px; 
    left: 20px;  */
    z-index: 1000; 
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 360px;
    height: 360px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s linear infinite;
    margin: 0; /* Remove margin */
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  /* Media query for mobile scaling */
@media (max-width: 768px) {

.auth-sections {
    display: grid;
    justify-content: space-around;
    margin-top: 20px;
}

.authentication-container h1 {
  padding-top: 20px;
  font-size: 1.5rem;
}

.auth-section {
  width: 100%;
  padding: 30px;
}

}
  
  