.verification-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f4f7;
  }
  
  .verification-content {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 500px;
    width: 100%;
  }
  
  .verification-content h1 {
    color: #415a77;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .verification-content p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 30px;
  }
  
  .login-button {
    display: inline-block;
    background-color: #1B263B;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .login-button:hover {
    background-color: #0D1B2A;
  }