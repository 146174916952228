@tailwind base;
@tailwind components;
@tailwind utilities;


.nav-link {
  display: block;
  padding: 0.5rem 0.75rem;
  text-decoration: none;
  color: #1f2937;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-link:hover {
  background-color: #f3f4f6;
}

.nav-link.active {
  color: #1d4ed8;
  background-color: #1d4ed8;
}

.nav-link.md {
  padding: 0;
}

.nav-link.md:hover {
  background-color: transparent;
  color: #264f7b;
}

.nav-link.md.active {
  background-color: transparent;
}


@media (max-width: 768px) {
    #navbar-default {
        position: absolute;
        top: 51px;
        left: 0;
        right: 0;
        /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); */
        z-index: 1000;
    }

    #navbar-default ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .nav-toggle {
        display: block;
    }




}
.logobutton button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
}

.logobutton button:hover {
    background-color: #1B263B;
}
.title-text {
    color: #1B263B;
}
br {   
    content: "";
    margin: 15%;
    display: block;
    margin-bottom: -20px; 
 }