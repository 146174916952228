@tailwind base;
@tailwind components;
@tailwind utilities;

.AboutUs {
    padding: 30px 20px 20px;
    text-align: center;
  }
  
  .aboutus h1 {
    font-size: 2.5rem;
    line-height: 1.4;
    margin-bottom: 20px; 
  }
  
   .aboutus-title {
    font-size: 2rem;
    margin-bottom: 10px; 
  }
  
  .aboutus p {
    font-size: 1.125rem;
    color: #888;
    max-width: 700px; 
    margin: 0 auto; 
    line-height: 1.6;
  }
  
  .main img {
    width: 100%;
    max-width: 800px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); 
    margin-top: 20px;
    margin-left: 20px;
  }
  
  .team-member img {
    width: 128px;
    height: 128px;
    object-fit: cover; 
  }
  
  .team-member {
    display: flex;
    flex-direction: column;
    align-items: center; 
  }

  .team-member:hover {
    background-color: #f8f8f8;
  }
  
