.ListingCard {
    background-color: white;
    width: 240px;
    height: 330px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto;
    cursor: pointer;
    position: relative;
    transition: transform 0.3s ease;
}

.ListingCard:hover {
    background-color: #E1E1E1;
}

.ListingCard h2, h3, p {
    color: #0D1B2A;
    padding: 3px 3px 5px 5px;
}

.ListingCard h2 {
    font-size: 1.1rem;
}

.ListingCard h3, p {
    font-size: .9rem;
}

.listing-image {
    width: 100%;
    height: 200px;
    max-height: 200px;
    object-fit: cover;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}


@media (max-width: 600px) {
    .ListingCard {
        transform: scale(0.9); 
        width: 270px; 
        height: 360px;
    }

    .ListingCard h2 {
        font-size: 1.2rem;
    }

    .ListingCard h3, p {
        font-size: 0.95rem;
    }
}