.account-page {
  padding: 20px;
  background-color: #f9f9f9;
}

.account-page-title {
  font-size: 1.5rem;
  letter-spacing: 3px;
}

.user-info-section {
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 30px;
  position: relative;
  border: 1px solid #ccc;
  height: 300px;
}

.user-info-section th,
.user-info-section td {
  font-size: 1rem;
}

.user-info-section th {
  margin-bottom: 15px;
}

.user-info-section td {
  line-height: 1.4;
  margin-bottom: 8px;
  color: #555;
}

.user-listings-section,
.user-favorites-section {
  margin-bottom: 40px;
}
.reservations-section {
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 30px;
  position: relative;
  border: 1px solid #ccc;
  height: 300px;
  overflow: hidden; /* Ensures the content stays inside the box */
  display: flex;
  flex-direction: column;
}

.reservations-section .reservation-content {
  overflow-y: auto; /* Adds vertical scroll */
  height: 100%; /* Ensures it takes up the full box height */
  padding: 10px;
}

.listing-card {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
  padding: 15px;
  margin: 5px 0;
}

.listing-card img {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
}

.listing-card h3 {
  font-size: 1.2rem;
  margin-bottom: 8px;
}

.listing-card p {
  font-size: 0.9rem;
  color: #555;
}

.h2-titles {
  font-size: 1.4rem;
  margin-bottom: 15px;
  margin-left: 10px;
}

.logout-button {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 8px 12px;
  margin-top: 10px;
  background-color: #2e3a46;
  color: white;
  display: flex;
}

.user-info-table {
  width: 90%;
  border-collapse: collapse;
  margin-top: 10px;
}

.user-info-table th {
  text-align: left;
  padding: 8px;
  background-color: #f0f0f0;
  font-weight: bold;
}

.user-info-table td {
  padding: 8px;
  border-bottom: 1px solid #e0e0e0;
}

.editor {
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  margin-top: 5px;
  margin-right: 5px;
  background-color: #415A77;
  font-size: 0.8rem;
  letter-spacing: 2px;
  height: 40px;
  width: 70px;
  box-shadow: 0 2px 4px darkslategray;
  align-self: flex-start;
}

.editor:hover {
  background-color: #0D1B2A;
}

.editor:active {
  box-shadow: 0 0 2px darkslategray;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.modal-content label {
  display: block;
  margin: 8px 0;
  font-size: 0.9rem;
  color: #555;
}

.modal-content h3 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  text-align: center;
  color: #333;
}

.modal-content input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 0.9rem;
  color: #333;
  background-color: #f9f9f9;
}

.modal-content input:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.4);
  outline: none;
}

.modal-content button {
  padding: 8px 15px;
  margin-right: 8px;
  margin-top: 15px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.sections-div {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (min-width: 1024px) {
  .sections-div {
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }

  .user-info-section,
  .user-notifications-section {
    flex: 1;
    height: 300px;
    max-width: 30%;
  }

  .user-info-section {
    margin-right: 10px;
  }
}

.user-notification-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
  margin-top: 10px;
}

.user-notification-table td {
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;
  color: #333;
  word-wrap: break-word;
}

.user-notification-table tr td:nth-child(odd) {
  background-color: #ffffff;
}

.no-notifications {
  text-align: center;
  padding: 15px;
  font-size: 1rem;
  color: #888;
}

.user-notifications-section {
  overflow: auto;
}

.notification-remove {
  text-align: center;
}

.remove-notif {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: 0.9rem;
}

.remove-notif:hover {
  background: none;
  color: #f2545b;
}